.productCardsWrapper[data-v-00a96114] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21.6875rem, 1fr));
  grid-gap: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.productWrapperNormal[data-v-00a96114] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21.6875rem, 1fr));
  grid-gap: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.box[data-v-00a96114] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.receipt-container[data-v-00a96114] {
  padding: 0.8125rem 0;
  padding-bottom: 2rem;
}
.dark .box[data-v-00a96114] {
  background: #353c53;
}
.headersection[data-v-00a96114] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-00a96114]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-00a96114]::before {
  background-image: url("../../assets/dashdark.png");
}
.btn-container[data-v-00a96114] {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 16.25rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.mr-6 svg[data-v-00a96114] {
  margin-right: 0.625rem;
}
.dark .btn-container[data-v-00a96114] {
  background-color: #353c53;
  color: #5a5d74;
}
.scan-barcode[data-v-00a96114] {
  display: flex;
  justify-content: center;
  align-items: center;
}
