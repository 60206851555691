.left-img[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 35vw;
}
.right-img[data-v-0d5d449f] {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 35vw;
}
.container[data-v-0d5d449f] {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.login-page[data-v-0d5d449f] {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .login-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.bg[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}
.dark .bg[data-v-0d5d449f] {
  background-color: #353c53;
}
.label[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}
.mt[data-v-0d5d449f] {
  margin-top: 3.3125rem;
}
.logo[data-v-0d5d449f] {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
.smtext[data-v-0d5d449f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.checkbox[data-v-0d5d449f] {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
}
.login-btn[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .login-btn[data-v-0d5d449f] {
  background: #353c53;
}
.login-btn[data-v-0d5d449f]:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}
.login-btn svg[data-v-0d5d449f] {
  margin-right: 0.625rem;
}
.abs-center[data-v-0d5d449f] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logout-page[data-v-0d5d449f] {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .logout-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.back-btn[data-v-0d5d449f] {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.back-btn[data-v-0d5d449f]::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}
.logged[data-v-0d5d449f] {
  font-size: 2rem;
}
.bold[data-v-0d5d449f] {
  cursor: pointer;
  text-decoration: underline;
}
