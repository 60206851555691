.input-field[data-v-bdb8ca0e] {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .input-field[data-v-bdb8ca0e] {
  background: #3b435e;
  color: #f6f7ff;
}
.input-field[data-v-bdb8ca0e]::-webkit-input-placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.input-field[data-v-bdb8ca0e]:-ms-input-placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.input-field[data-v-bdb8ca0e]::placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.dark .input-field[data-v-bdb8ca0e]::-webkit-input-placeholder {
  color: #5a5d74;
}
.dark .input-field[data-v-bdb8ca0e]:-ms-input-placeholder {
  color: #5a5d74;
}
.dark .input-field[data-v-bdb8ca0e]::placeholder {
  color: #5a5d74;
}
.sm[data-v-bdb8ca0e] {
  max-width: 2.8125rem;
  padding: 0;
  width: 100%;
  height: 2.125rem;
  text-align: center;
}
.smtext[data-v-bdb8ca0e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding: 0.5625rem 0.75rem !important;
}
.small[data-v-bdb8ca0e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 9.0625rem;
  width: 100%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.dark .small[data-v-bdb8ca0e] {
  background: #353c53;
}
.large[data-v-bdb8ca0e] {
  height: 5.0625rem;
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-bdb8ca0e]::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-bdb8ca0e]:-ms-input-placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-bdb8ca0e]::placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.prefix-input[data-v-bdb8ca0e] {
  padding: 0 !important;
  width: 100%;
  box-shadow: none;
  height: 100%;
}
.prefix[data-v-bdb8ca0e] {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 0.125rem;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dark .sm[data-v-bdb8ca0e],
.dark .lightinput[data-v-bdb8ca0e] {
  background-color: #464f6d !important;
}
