.btn[data-v-b6fd0155] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9.0625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.dark .btn[data-v-b6fd0155] {
  background-color: #353c53;
}
.text[data-v-b6fd0155] {
  margin-left: 0.625rem;
  font-weight: 400 !important;
}
.dark .text[data-v-b6fd0155] {
  color: #5a5d74;
}
.dark .lightbtn[data-v-b6fd0155] {
  background-color: #3b435e !important;
}
.btn[data-v-b6fd0155]:active {
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
.disabled[data-v-b6fd0155] {
  pointer-events: none;
  cursor: not-allowed !important;
}
.mx-auto[data-v-b6fd0155] {
  margin: 0 auto;
}
.dark .lightish[data-v-b6fd0155] {
  background-color: #464f6d;
  color: #5a5d74;
}
