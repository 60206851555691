.box[data-v-62c1e374] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-62c1e374] {
  background-color: #3b435e;
}
.smbox[data-v-62c1e374] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-62c1e374] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-62c1e374] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-62c1e374] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-62c1e374] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-62c1e374] {
  background-color: #464f6d;
}
.upload img[data-v-62c1e374] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-62c1e374] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-62c1e374],
.smlabel img[data-v-62c1e374] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-62c1e374] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-62c1e374] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-62c1e374] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-62c1e374] {
  object-fit: cover;
}
.equal[data-v-62c1e374] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
