.label[data-v-8183c7f2] {
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.125rem;
}
.msg[data-v-8183c7f2] {
  font-size: 0.875rem;
  font-family: "Poppins";
  line-height: 1.125rem;
  margin-top: 0.125rem;
}
