.productWrapper[data-v-88deac86] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-88deac86] {
  background: #353c53;
}
.purpleBox[data-v-88deac86] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-88deac86] {
  color: #3b435e;
}
.shoeImgnew[data-v-88deac86] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-88deac86],
.heading[data-v-88deac86] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-88deac86] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-88deac86] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-88deac86] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-88deac86],
.size span[data-v-88deac86],
.consignment span[data-v-88deac86] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-88deac86],
.size[data-v-88deac86],
.consignment[data-v-88deac86] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-88deac86] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-88deac86] {
  width: 100%;
}
