.analytics[data-v-cad2d7de] {
  display: grid;
  grid-gap: 1.25rem 2.5rem;
  grid-template-rows: 21.5625rem 1fr;
}
.transaction-container[data-v-cad2d7de] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.span[data-v-cad2d7de] {
  display: grid;
  grid-column: span 2;
  grid-template-columns: repeat(auto-fill, minmax(17.3125rem, 1fr));
  grid-gap: 3.75rem;
  max-height: 27rem;
  padding-bottom: 1rem;
  overflow-y: auto;
}
.span-2[data-v-cad2d7de] {
  display: grid;
  grid-gap: 1.25rem 2.5rem;
  grid-column: span 2;
  grid-template-columns: 32.5rem 1fr;
}
