.productWrapper[data-v-a3920300] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-a3920300] {
  background: #353c53;
}
.greenBox[data-v-a3920300] {
  background-color: #51b4b4;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .greenBox[data-v-a3920300] {
  color: #353c53;
}
.shoeImg[data-v-a3920300] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
}
.prodTitle[data-v-a3920300] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.model[data-v-a3920300] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;

  margin: 0.3125rem 0;
}
.style[data-v-a3920300] {
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-top: 0.3125rem;
}
.style span[data-v-a3920300] {
  font-weight: 600;
}
.whiteBoxWrap[data-v-a3920300] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.qr[data-v-a3920300] {
  border-radius: 0.4375rem;
  margin-top: 0.625rem;
  margin-bottom: 0.875rem;
  padding: 0.75rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.25);
  border-radius: 0.4375rem;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
}

/* .title {
  position: absolute;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.75);
  top: 50%;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 0.25rem;
  font-size: 0.625rem;
  opacity: 0.5;
  color: #fff;
  pointer-events: none;
  text-align: center;
  width: max-content;
  height: max-content;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
} */
.qrcontainer[data-v-a3920300] {
  display: flex;
  align-items: center;
}
.qr-print[data-v-a3920300] {
  width: 30px;
  height: 30px;
}
.left[data-v-a3920300] {
  text-align: left !important;
  align-items: start;
}
.center[data-v-a3920300] {
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}
.capitalize[data-v-a3920300] {
  text-transform: capitalize;
}
